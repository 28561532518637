<template>
    <v-card>
        <v-overlay :value="loading"></v-overlay>
        <v-card-title>
            {{ service.client_name }}
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col>
                    <v-simple-table
                        dense
                    >
                        <thead>
                            <tr>
                                <th>Szerződés</th>
                                <th>Felhasználónév</th> 
                                <th>Státusz</th>
                                <th>MAC</th>
                                <th>IP</th>
                                <th>Részletek</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="pppoe in pppoe_users" :key="pppoe.id">
                                <td>{{ pppoe.contract_id }}</td>
                                <td>{{ pppoe.pppoe_username }}</td>
                                <td>
                                    <v-icon small v-if="pppoe.online" color="green">mdi-checkbox-blank-circle</v-icon>
                                    <v-icon small v-else color="red">mdi-checkbox-blank-circle</v-icon>
                                </td>
                                <td>{{ pppoe.mac }}</td>
                                <td>
                                    <a v-if="pppoe.antenna"  target="_blank" :href='"http://" + pppoe.ip + ":" + pppoe.antenna.properties.webUiPort'>{{ pppoe.ip }}</a>
                                    <a v-else target="_blank" :href='"http://" + pppoe.ip'>{{ pppoe.ip }}</a>
                                </td>

                                <td>
                                    <v-btn color="primary" :to="{name: 'radius.radacct.view', params: {username: pppoe.pppoe_username}}" x-small>
                                        Radacct
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-expansion-panels
                        multiple
                    >
                        <v-expansion-panel
                            v-for="pppoe in pppoe_users"
                            :key="pppoe.id"
                        >
                            <v-expansion-panel-header>{{ pppoe.pppoe_username }}</v-expansion-panel-header>

                            <v-expansion-panel-content>
                                <template v-if="pppoe.antenna">
                                    <v-simple-table
                                        dense
                                    >
                                        <tbody>
                                            <tr>
                                                <td>Online:</td> 
                                                <td>
                                                    <v-icon small v-if="pppoe.antenna.status.online" color="green">mdi-checkbox-blank-circle</v-icon>
                                                    <v-icon small v-else color="red">mdi-checkbox-blank-circle</v-icon>    
                                                </td>
                                            </tr>
                                            <tr> 
                                                <td>Eszköz neve:</td>
                                                <td>{{pppoe.antenna.properties.deviceTag}}</td>
                                            </tr>
                                            <tr>
                                                <td>Antenna típus:</td>
                                                <td>{{pppoe.antenna.properties.product}}</td>
                                            </tr>
                                            <tr>
                                                <td>Firmware:</td>
                                                <td>{{pppoe.antenna.properties.fwVersion }}</td>
                                            </tr>

                                            <tr>
                                                <td>Ethernet állapot:</td>
                                                <td>{{ethernetStatus(pppoe.antenna.properties['ifStatus@eth0'])}}</td>
                                            </tr>                                            
                                            <tr>
                                                <td>Központ:</td>
                                                <td>{{pppoe.antenna.properties.topologyBranch}}</td>
                                            </tr>
                                            <tr> 
                                                <td>Jelszint (jelenlegi):</td>
                                                <td>
                                                    {{pppoe.antenna.properties['signal@ath0~current']}} dBm
                                                    ({{pppoe.antenna.properties['chain0Signal@ath0~current']}}/{{pppoe.antenna.properties['chain1Signal@ath0~current']}} dBm)
                                                </td>
                                            </tr>
                                            <tr> 
                                                <td>Jelszint (5 perccel ezelőtt):</td>
                                                <td>
                                                    {{pppoe.antenna.properties['signal@ath0~5min']}} dBm
                                                    ({{pppoe.antenna.properties['chain0Signal@ath0~5min']}}/{{pppoe.antenna.properties['chain1Signal@ath0~5min']}} dBm)
                                                </td>
                                            </tr>
                                            <tr> 
                                                <td>Jelszint (30 perccel ezelőtt):</td>
                                                <td>
                                                    {{pppoe.antenna.properties['signal@ath0~30min']}} dBm
                                                    ({{pppoe.antenna.properties['chain0Signal@ath0~30min']}}/{{pppoe.antenna.properties['chain1Signal@ath0~30min']}} dBm)
                                                </td>
                                            </tr>

                                        </tbody>
                                    </v-simple-table>
                                </template>

                                <template v-else>
                                    Nem találtam AirControl-ban az antennát.
                                </template>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import {API} from '../../api'

export default {
    name: 'ServiceInfo',
    props: ['service'],
    data: () => ({
        loading: true,
        pppoe_users: [],
    }),
    mounted: function(){
            API.instance.get(`ucrm/pppoe/${this.service.client_id}`)
                .then((response) => {
                    this.pppoe_users = response.data
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.loading = false)
    },
    watch: {
        service(val) {
            this.loading = true

            API.instance.get(`ucrm/pppoe/${val.client_id}`)
                .then((response) => {
                    this.pppoe_users = response.data
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.loading = false
                })
        },
    },
    methods: {
        onRadacctClick: function(e) {
            console.log(e)
        },
        ethernetStatus: function(value){
            /*
                "1": "Unplugged",
                "18": "10Mbps-Half",
                "34": "10Mbps-Full",
                "20": "100Mbps-Half",
                "36": "100Mbps-Full",
                "24": "1000Mbps-Half",
                "40": "1000Mbps-Full"
            */
            switch(value) {
                case 1:
                    return '100Mbps-Full'
                default:
                    return value
            }
                
        }
    }
}
</script>