<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="serviceDialog" max-width="850px">
      <ServiceInfo :service="selectedService" />
    </v-dialog>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Számlázási infók</v-card-title>
          <v-card-text>
            <ul>
              <li>Bankszámlaszám: 11745080-29900167 / OTP</li>
            </ul>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col>
        <RadiusInfo />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              prepend-icon="mdi-magnify"
              label="Keresés"
              hide-details
              single-line
              v-on:keyup="onSearch"
            >
            </v-text-field>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="tableHeaders"
              :items="services"
              :search="search"
              :options.sync="options"
              :footer-props="tableFooter"
              dense
            >
              <template v-slot:item.client_name="{ item }">
                <div v-if="!item.has_suspended_service">
                  <a
                    target="_blank"
                    :href="'https://ucrm.keviwlan.hu/client/' + item.client_id"
                    >{{ item.client_name }}</a
                  >
                  <v-chip
                    style="margin-left: 5px"
                    v-if="item.has_outage"
                    x-small
                    color="yellow"
                  >
                    Kimaradás
                  </v-chip>
                </div>
                <!-- Korlátozott ügyfél !-->
                <div v-else>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <a
                        target="_blank"
                        :href="
                          'https://ucrm.keviwlan.hu/client/' + item.client_id
                        "
                        style="color: red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ item.full_name || item.company_name }}
                      </a>
                      <v-chip v-if="item.has_outage" x-small color="yellow">
                        Kimaradás
                      </v-chip>
                    </template>
                    <span>
                      <b>Számlatartozás miatt korlátozva:</b>
                      <ul>
                        <li
                          v-for="item in getUnpaidInvoices(item)"
                          :key="item.invoice_number"
                        >
                          <span v-if="item.invoice_status == 1">
                            {{ item.invoice_number }}
                            <b>Kiegyenlítetlen</b> (Lejárt:
                            {{ item.expired_days }} napja): {{ item.total }}Ft
                          </span>
                          <span v-else>
                            {{ item.invoice_number }}
                            <b>Részben kiegyenlített</b> (Lejárt:
                            {{ item.expired_days }} napja): {{ item.total }}Ft
                            ebből fizetve: {{ item.amount_paid }}
                          </span>
                        </li>
                      </ul>
                    </span>
                  </v-tooltip>
                </div>
              </template>

              <template v-slot:item.street1="{ item }">
                <a
                  target="_blank"
                  :href="
                    'https://ucrm.keviwlan.hu/client/service/' + item.service_id
                  "
                >
                  {{ item.street1 }}
                </a>
              </template>

              <template v-slot:item.details="{ item }">
                <v-btn icon @click="onServiceInfoClicked(item)">
                  <v-icon>mdi-information-outline</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { API } from "../../../api/index.js";
import RadiusInfo from "../../../components/radius/RadiusInfo";
import ServiceInfo from "../../../components/ucrm/ServiceInfo";

export default {
  name: "services.index",
  components: {
    RadiusInfo,
    ServiceInfo,
  },
  data: () => ({
    loading: true,
    services: [],
    unpaidInvoices: {},
    search: "",
    options: {},
    serviceDialog: false,
    selectedService: {},
    tableHeaders: [
      {
        width: "5%",
        text: "Végpont ID",
        value: "service_id",
        sortable: false,
      },
      {
        width: "30%",
        text: "Név",
        value: "client_name",
        sortable: true,
      },
      {
        width: "15%",
        text: "Település",
        value: "city",
        sortable: true,
      },
      {
        width: "50%",
        text: "Cím",
        value: "street1",
        sortable: true,
      },
      {
        text: "Részletek",
        value: "details",
        sortable: false,
      },
    ],
    tableFooter: {
      "items-per-page-options": [25, 50, 100],
    },
  }),
  mounted: function () {
    this.$store.commit("app/setTitle", "Végpontok");
    API.instance
      .get("ucrm/services")
      .then((response) => {
        this.services = response.data;
      })
      .catch((err) => {
        console.log(err);
      });
    API.instance
      .get("ucrm/unpaid_invoices")
      .then((response) => {
        this.unpaidInvoices = response.data;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => (this.loading = false));
  },
  methods: {
    getUnpaidInvoices: function (service) {
      if (service.client_id in this.unpaidInvoices) {
        return this.unpaidInvoices[service.client_id];
      }
    },
    onSearch: function () {
      this.options.page = 1;
    },
    onServiceInfoClicked: function (item) {
      this.selectedService = item;
      this.serviceDialog = true;
    },
  },
};
</script>
