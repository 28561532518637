<template>
    <v-card>
        <v-card-title>Radius</v-card-title>
        <v-card-text>
            <v-simple-table
                dense
            >
                <tr>
                    <td><b>Összes felhasználó:</b></td>
                    <td>{{ radiusStatus.all }}</td>
                </tr>
                <tr>
                    <td><b>Ebből Online:</b></td>
                    <td>{{ radiusStatus.online }}</td>
                </tr>

            </v-simple-table>
        </v-card-text>
    </v-card>
</template>

<script>
import {API} from '../../api'

export default {
    name: 'RadiusInfo',
    data: () => ({
        loading: true,
        radiusStatus: {}
    }),
    mounted: function(){
        API.instance.get('radius/status')
            .then((response) => {
                this.radiusStatus = response.data
            })
            .catch((err) => console.log(err))
    }

}
</script>