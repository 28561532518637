var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-dialog',{attrs:{"max-width":"850px"},model:{value:(_vm.serviceDialog),callback:function ($$v) {_vm.serviceDialog=$$v},expression:"serviceDialog"}},[_c('ServiceInfo',{attrs:{"service":_vm.selectedService}})],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v("Számlázási infók")]),_c('v-card-text',[_c('ul',[_c('li',[_vm._v("Bankszámlaszám: 11745080-29900167 / OTP")])])])],1)],1),_c('v-col',[_c('RadiusInfo')],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Keresés","hide-details":"","single-line":""},on:{"keyup":_vm.onSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.services,"search":_vm.search,"options":_vm.options,"footer-props":_vm.tableFooter,"dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.client_name",fn:function(ref){
var item = ref.item;
return [(!item.has_suspended_service)?_c('div',[_c('a',{attrs:{"target":"_blank","href":'https://ucrm.keviwlan.hu/client/' + item.client_id}},[_vm._v(_vm._s(item.client_name))]),(item.has_outage)?_c('v-chip',{staticStyle:{"margin-left":"5px"},attrs:{"x-small":"","color":"yellow"}},[_vm._v(" Kimaradás ")]):_vm._e()],1):_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticStyle:{"color":"red"},attrs:{"target":"_blank","href":'https://ucrm.keviwlan.hu/client/' + item.client_id}},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.full_name || item.company_name)+" ")]),(item.has_outage)?_c('v-chip',{attrs:{"x-small":"","color":"yellow"}},[_vm._v(" Kimaradás ")]):_vm._e()]}}],null,true)},[_c('span',[_c('b',[_vm._v("Számlatartozás miatt korlátozva:")]),_c('ul',_vm._l((_vm.getUnpaidInvoices(item)),function(item){return _c('li',{key:item.invoice_number},[(item.invoice_status == 1)?_c('span',[_vm._v(" "+_vm._s(item.invoice_number)+" "),_c('b',[_vm._v("Kiegyenlítetlen")]),_vm._v(" (Lejárt: "+_vm._s(item.expired_days)+" napja): "+_vm._s(item.total)+"Ft ")]):_c('span',[_vm._v(" "+_vm._s(item.invoice_number)+" "),_c('b',[_vm._v("Részben kiegyenlített")]),_vm._v(" (Lejárt: "+_vm._s(item.expired_days)+" napja): "+_vm._s(item.total)+"Ft ebből fizetve: "+_vm._s(item.amount_paid)+" ")])])}),0)])])],1)]}},{key:"item.street1",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":'https://ucrm.keviwlan.hu/client/service/' + item.service_id}},[_vm._v(" "+_vm._s(item.street1)+" ")])]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onServiceInfoClicked(item)}}},[_c('v-icon',[_vm._v("mdi-information-outline")])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }